import React from 'react'
import classnames from 'classnames'
import { Icon } from 'semantic-ui-react'
import { NavLink, useParams, generatePath } from 'react-router-dom'
import { InputField, SelectField } from '@labsavvyapp/ui-components'

import { ForwardIcon } from '../../../components/Icons/light'
import { LAB_REPORTS } from '../../../config/routes'
import isAnEnumUnits from '../../../utils/is-an-enum-units'
import capitalize from '../../../utils/capitalize'
import style from './LabReportTestResult.module.css'

export default function LabReportTestResult({ editing, test }) {
  const { labReportId } = useParams()

  function renderEditField() {
    if (!test) {
      return null
    }

    if (isAnEnumUnits(test.units)) {
      return (
        <SelectField
          fluid
          className={style.field}
          name={test.id}
          options={test.units.split('/').map((value) => {
            const text = value && capitalize(value)

            return {
              value: value.toLowerCase(),
              text,
            }
          })}
        />
      )
    }

    return <InputField className={style.field} name={test.id} />
  }

  return (
    <NavLink
      data-test="link-result"
      to={
        (!editing &&
          generatePath(LAB_REPORTS.resultDetails, {
            labReportId,
            resultId: test.id,
          })) ||
        undefined
      }
      className={classnames(style.container, {
        [style.statusAbnormal]: test.status === 'abnormal',
        [style.statusBorderline]: test.status === 'borderline',
        [style.editing]: editing,
      })}
    >
      <Icon
        className={style.statusIcon}
        name="circle"
        size="small"
        color={
          (test.value === null && 'grey') ||
          (test.status === 'normal' && 'green') ||
          (test.status === 'borderline' && 'yellow') ||
          (test.status === 'abnormal' && 'red') ||
          'grey'
        }
      />
      <div className={style.name}>{test.name}</div>
      {editing && renderEditField()}
      {editing && test && test.units && (
        <div className={style.units}>{test.units}</div>
      )}
      {!editing && test && (
        <div className={style.value}>
          {(isAnEnumUnits(test.units) && capitalize(test.value)) || test.value}{' '}
          {!isAnEnumUnits(test.units) && test.units}
        </div>
      )}
      {!editing && <ForwardIcon className={style.angle} />}
    </NavLink>
  )
}
