import gql from 'graphql-tag'

export const ListProviders = gql`
  query ListProviders {
    listProviders(limit: 20) {
      providers {
        _id
        name
      }
    }
  }
`

export const GetProvider = gql`
  query GetProvider($id: MongoID!) {
    getProvider(id: $id) {
      name
    }
  }
`

export const ListPartnerProjectProviders = gql`
  query ListPartnerProjectProviders($projectId: MongoID!) {
    listPartnerProjectProviders(project_id: $projectId) {
      account_number
      provider {
        _id
        created_at
        updated_at
        name
        short_name
        key
      }
    }
  }
`
