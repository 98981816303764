import React, { useRef } from 'react'

import style from './index.module.css'
import paperPlaneIcon from './paper-plane-icon.svg'
import { br2nl } from '../utils'

export default function Composer({ disabled, onSend }) {
  const inputField = useRef()

  const sendMessage = () => {
    const messageText = inputField.current.innerHTML

    if (!messageText) return

    onSend && onSend(br2nl(messageText))
    inputField.current.innerHTML = ''
  }

  function handleEnterKey(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault()

      sendMessage()
    }
  }

  return (
    <section className={style.container}>
      <div className={style.inputContainer}>
        <div
          className={style.input}
          contentEditable={disabled ? 'false' : 'true'}
          spellCheck
          placeholder="Aa"
          ref={inputField}
          onKeyDown={handleEnterKey}
          data-test="composer"
        ></div>
      </div>

      <input
        className={style.sendButton}
        type="image"
        src={paperPlaneIcon}
        alt="send-button"
        onClick={sendMessage}
        {...((disabled && { disabled }) || {})}
      />
    </section>
  )
}
