/* eslint-disable */
import React, { useEffect, useId, useState } from 'react'
import { Icon, Popup } from 'semantic-ui-react'

import { CheckList } from '@labsavvyapp/ui-components'
import formatterPrice from '../../../utils/formatter-price'
import style from './NewLabOrder.module.css'

export default function NewLabOrder({
  selectedTests,
  selectedPanels,
  selectedPackage,
  selectedCompendiumPanels,
  labOrderType,
  labType,
  miscPrices,
  setState,
}) {
  const [individualTestsChecklistItems, setIndividualTestsChecklistItems] =
    useState([])

  const [groupsChecklistItems, setGroupsChecklistItems] = useState([])

  /**
   *  Map individual tests for the Checklist component format
   */
  useEffect(() => {
    setIndividualTestsChecklistItems(
      selectedTests.map(({ id, result }, index) => ({
        text: (
          <div key={result.code + index} className={style.checkListTest}>
            <span className={style.checkListTestCode}>{result.code}</span>
            <span>{result.name}</span>
          </div>
        ),
        value: id,
        checked: true,
      })),
    )
  }, [selectedTests])

  /**
   * Map panels for the Checklist component format-  Existing Packages
   */
  useEffect(() => {
    if (selectedCompendiumPanels.length) return

    setGroupsChecklistItems(
      selectedPanels.map(({ code, name, tests, price }) => {
        const groupTests = tests.map(({ result }) => ({
          text: (
            <div className={style.checkListTest}>
              <span className={style.checkListTestCode}>{result.code}</span>
              <span>{result.name}</span>
            </div>
          ),
          value: result.code,
        }))

        return {
          group: { code, name, checked: true, price },
          tests: groupTests,
        }
      }),
    )
  }, [selectedPanels, selectedCompendiumPanels])

  /**
   * Map panels for the Checklist component format - Custom Packages
   */

  useEffect(() => {
    if (selectedPanels.length) return

    setGroupsChecklistItems(
      selectedCompendiumPanels.map(
        ({ code, name, results, price, partnerPricing }) => {
          const groupTests = results.map((result) => ({
            text: (
              <div className={style.checkListTest}>
                <span className={style.checkListTestCode}>{result.code}</span>
                <span>{result.name}</span>
              </div>
            ),
            value: result.code,
          }))

          return {
            group: { code, name, checked: true, price, partnerPricing },
            tests: groupTests,
          }
        },
      ),
    )
  }, [selectedPanels.length, selectedCompendiumPanels])

  const getMiscSubtotal = (query) => {
    return miscPrices.reduce((a, b) => a + (b[query] || 0), 0)
  }

  const removeTestHandler = (code) => {
    //only allow custom packages to delete tests
    if (+labType.value === 2) {
      // update state used for display
      const newGroupChecklistItems = groupsChecklistItems.filter(
        ({ group }) => group.code != code,
      )
      setGroupsChecklistItems(newGroupChecklistItems)

      // update state used for review page
      const newSelectedCompendiumPanels = selectedCompendiumPanels.filter(
        (panel) => panel.code != code,
      )
      setState({ selectedCompendiumPanels: newSelectedCompendiumPanels })
    }
  }

  return (
    <div className={style.newLabOrderContainer}>
      <div className={style.panelContainer} key={useId}>
        <div className={style.pricingHeader}>
          <span>Miscellaneous Fees</span>
          <span className={style.pullRight}>
            {miscPrices.length < 1
              ? '-'
              : labOrderType.queryStringValue.includes('direct_to_lab')
              ? formatterPrice(getMiscSubtotal('contract_price'))
              : formatterPrice(getMiscSubtotal('retail_price'))}
          </span>
        </div>
        {miscPrices.map(({ name, code, contract_price, retail_price }) => {
          return (
            <div className={style.pricingBreakdown}>
              <span>{name}</span>
              <span className={style.pullRight}>
                <b className={style.pricingBreakdownTxt}>
                  {labOrderType?.queryStringValue.includes('direct_to_lab')
                    ? formatterPrice(contract_price)
                    : formatterPrice(retail_price)}
                </b>
              </span>
            </div>
          )
        })}
        {miscPrices.length < 1 && (
          <div className={style.pricingBreakdown}>
            <span>
              No Miscellaneous Fees found{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="If you think this is an issue, please contact your LabSavvy Administrator"
                basic
              />
            </span>
            <span className={style.pullRight}>
              <b className={style.pricingBreakdownTxt}>-</b>
            </span>
          </div>
        )}
      </div>
      {/* parent */}
      <div className={style.panelContainer} key={'misc_code_' + Math.random()}>
        {selectedPackage && (
          <div className={style.pricingHeader}>
            <span>{selectedPackage?.name}</span>
            <span className={style.pullRight}>
              {labOrderType?.queryStringValue?.includes('direct_to_lab')
                ? formatterPrice(selectedPackage?.partnerPrice)
                : formatterPrice(selectedPackage?.retailPrice)}
            </span>
          </div>
        )}
      </div>
      {/* children */}
      {groupsChecklistItems.length !== 0 &&
        groupsChecklistItems.map(({ group, tests }) => (
          <div className={style.panelContainer} key={group.code}>
            <CheckList
              readOnly
              title={
                <div className={style.checkListTest}>
                  <span className={style.checkListTestCode}>{group.code}</span>
                  <span>{group.name}</span>
                  {!labType.queryStringValue.includes('existing_package') && (
                    <span className={style.pullRight}>
                      {labOrderType.queryStringValue.includes('direct_to_lab')
                        ? formatterPrice(group.partnerPricing?.contractPrice)
                        : formatterPrice(group.partnerPricing.retailPrice)}
                    </span>
                  )}
                </div>
              }
              group={{
                value: group.code,
                checked: group.checked,
              }}
              items={tests}
              showCheckboxes={false}
              onDeleteClick={removeTestHandler}
            />
          </div>
        ))}

      {individualTestsChecklistItems.length !== 0 && (
        <CheckList
          title="Individual Tests"
          items={individualTestsChecklistItems}
          readOnly
          showCheckboxes={false}
        />
      )}
    </div>
  )
}
/* eslint-enable */
