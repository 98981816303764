import React from 'react'
import { Link, generatePath } from 'react-router-dom'

import { NameInitials, ListRow, ProfilePhoto } from '@labsavvyapp/ui-components'
import { CLIENTS } from '../../../config/routes'
import { ForwardIcon } from '../../../components/Icons/light'
import FancyID from '../../../components/Utils/FancyID'
import getAge from '../../../utils/get-age'
import dateFormatFromIso from '../../../utils/date-format-from-iso'

import style from './ClientList.module.css'

export default function ClientListRow({ id: clientId, data }) {
  const profilePhotoURL = data.profilePhoto && data.profilePhoto.url

  return (
    <ListRow
      data-test="client-list-row"
      as={Link}
      to={generatePath(CLIENTS.view, { clientId })}
    >
      <span className={style.rowPhoto}>
        {profilePhotoURL ? (
          <ProfilePhoto
            className={style.ProfilePhoto}
            url={profilePhotoURL}
            size={40}
          />
        ) : (
          data.name &&
          data.name.first &&
          data.name.last && (
            <NameInitials
              firstName={data.name.first}
              lastName={data.name.last}
              size={40}
            />
          )
        )}
      </span>
      <span className={style.name}>
        {data && data.name && [data.name.first, data.name.last].join(' ')}
      </span>
      <span className={style.patient_id}>
        <FancyID id={clientId} />
      </span>
      <span className={style.gender}>
        {data &&
          data.gender &&
          data.gender
            .toLowerCase()
            .replace(
              /^[\u00C0-\u1FFF\u2C00-\uD7FF\w]|\s[\u00C0-\u1FFF\u2C00-\uD7FF\w]/g,
              (letter) => letter.toUpperCase(),
            )}
      </span>
      <span className={style.age}>{data && data.dob && getAge(data.dob)}</span>
      <span className={style.dob}>
        {data.dob && dateFormatFromIso(data.dob, 'MM/DD/YYYY')}
      </span>
      <span className={style.arrow}>
        <ForwardIcon />
      </span>
    </ListRow>
  )
}
