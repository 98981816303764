import gql from 'graphql-tag'

export const GetPatient = gql`
  query GetPatient($id: MongoID!) {
    getPatient(id: $id) {
      _id
      data {
        name {
          first
          last
          display
        }
        profile_photo {
          _id
          url
        }
        dob
        gender
        address {
          street
          city
          state
          zip
        }
        phones {
          number
          type
        }
        emails {
          address
        }
      }
      partner {
        _id
      }
    }
  }
`

export const ListPatients = gql`
  query ListPatients($filter: PatientFilter) {
    listPatients(limit: 50, filter: $filter) {
      patients {
        _id
        data {
          name {
            first
            last
            display
          }
          profile_photo {
            _id
            url
          }
        }
      }
    }
  }
`
