import React, { useState, useEffect } from 'react'
import { Modal } from 'semantic-ui-react'

import { Button, Search, InfiniteScrollList } from '@labsavvyapp/ui-components'
import ListItem from './ListItem.jsx'
import Results from './Results.jsx'

import style from './TestSelectionModal.module.css'

const TestSelectionModal = ({
  onNext,
  onSearch,
  onSubmit,
  onClose,
  hasMore,
  open,
  items,
}) => {
  const firstItem = items ? items.find((item) => !item.selected) : undefined
  const [selectedCode, setSelectedCode] = useState(firstItem?.code || undefined)
  const [modalOpen, setModalOpen] = useState(open)

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  useEffect(() => {
    if (items?.length === 0) setSelectedCode(undefined)
    if (!items?.find((item) => item.code === selectedCode) && items?.length > 0)
      setSelectedCode(firstItem?.code || undefined)
  }, [onSearch])

  const handleSelect = (code) => setSelectedCode(code)
  let selected = items?.find((item) => item.code === selectedCode)

  return (
    <Modal open={modalOpen} size="large" closeIcon={false}>
      <div className={style.content}>
        {onSearch && (
          <Search
            className={style.search}
            placeholder="Search for a test by name or test code"
            showNoResults={false}
            onSearchChange={(search) => onSearch(search)}
            useAutoCorrect={false}
          />
        )}
        <div className={style.tables}>
          {items && items.length > 0 ? (
            <>
              <div className={style.panelsContainer}>
                <ul className={style.list}>
                  <InfiniteScrollList
                    dataLength={items?.length || 0}
                    scrollableTarget="test-selection-list"
                    hasMore={hasMore}
                    next={onNext}
                    saveScrollPosition={false}
                  >
                    {items.map((item) => (
                      <ListItem
                        key={item.code}
                        item={item}
                        selected={item.code === selectedCode}
                        disabled={item.selected}
                        onClick={() => handleSelect(item.code)}
                      />
                    ))}
                  </InfiniteScrollList>
                </ul>
              </div>
              <Results selected={selected} />
            </>
          ) : (
            <div className={style.noResults}>
              <h2>No results!</h2>
            </div>
          )}
        </div>
        <div className={style.actions}>
          <Button className={style.cancelButton} onClick={onClose}>
            Cancel
          </Button>
          <Button
            className={style.addButton}
            onClick={() => onSubmit(selectedCode)}
            disabled={!selectedCode}
          >
            Add test
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default TestSelectionModal
