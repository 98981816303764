/* eslint-disable */
import React, { useEffect } from 'react'
import delay from 'await-delay'
import { useHistory } from 'react-router'
import { useApolloClient } from '@apollo/react-hooks'
import { SIGN } from '../../../config/routes'
import { removeCookies } from '../../../utils/auth'
import Loader from '../../../components/Loader/Loader'

const DELAY = 1000

export default function SignOutPage() {
  const { push } = useHistory()
  const client = useApolloClient()
  useEffect(() => {
    async function signOut() {
      client.clearStore()
      await delay(DELAY)
      removeCookies()
      push(SIGN.in)
    }

    signOut()
  }, [push])

  return <Loader>Signing out</Loader>
}
/* eslint-enable */
