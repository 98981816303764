import React, { useState, useReducer } from 'react'
import { Modal, Grid, Segment } from 'semantic-ui-react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import delay from 'await-delay'
import { useIntl } from 'react-intl'
import { camelizeKeys } from 'humps'
import get from 'lodash/get'
import {
  Button,
  Form,
  InputField,
  DateField,
  PhoneInputField,
  SelectField,
  PhoneNumberTypeSelectField,
  ProfileImageUploadField,
  SubmitButton,
  validate,
  SavingModal,
  SavedModal,
  ErrorModal,
} from '@labsavvyapp/ui-components'

import style from './FormPage.module.css'
import { CLIENTS } from '../../../config/routes'
import { STATES } from '../../../constants/states'
import ZIPCodeField from '../../../components/ZIPCodeField/ZIPCodeField'
import { UploadImage } from '../../../graphql/files/mutations.js'
import { GetPatient } from '../../../graphql/clients/queries.js'
import {
  CreatePatient,
  DeletePatient,
  UpdatePatient,
} from '../../../graphql/clients/mutations.js'
import { GetMe } from '../../../graphql/user/queries.js'
import capitalize from '../../../utils/capitalize'
import dateFormatFromIso from '../../../utils/date-format-from-iso'
import dateIsoUtcString from '../../../utils/date-iso-utc-string'
import { isValidDate, isPastDate, isLeapYearCheck } from '../../../utils/date'

export default function PatientFormPage() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const [state, setState] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    { loading: false, deleteAskModal: false, selectedPartner: null },
  )

  // Modals
  const [showSavingModal, setShowSavingModal] = useState(false)
  const [showSavedModal, setShowSavedModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [apiError, setApiError] = useState('')

  // Fetch user information
  const { data: userData } = useQuery(GetMe)
  const parsedUserData = camelizeKeys(userData)
  const consumerTitle = capitalize(
    get(parsedUserData, 'getMe.project.consumersTitle', 'Client'),
  )

  /**
   * Error handling
   */
  const handleServerError = (error) => {
    setShowSavingModal(false)
    setShowErrorModal(true)
    setApiError(error?.graphQLErrors[0]?.message)
  }

  // Grab the patient ID form the URL
  const { clientId } = useParams()

  // Queries
  const { data } = useQuery(GetPatient, {
    variables: {
      id: clientId,
    },
    skip: !clientId,
  })
  const clientData = data && data.getPatient
  const clientPhoto =
    clientData &&
    clientData.data.profile_photo &&
    clientData.data.profile_photo.url

  // Mutations
  const [createPatient] = useMutation(CreatePatient)
  const [updatePatient] = useMutation(UpdatePatient, {
    refetchQueries: [
      {
        query: GetPatient,
        variables: { id: clientId },
      },
    ],
  })
  const [uploadImage] = useMutation(UploadImage)
  const [deletePatient] = useMutation(DeletePatient)

  // Cancel button URL
  const previousPageURL = clientId
    ? generatePath(CLIENTS.view, { clientId })
    : CLIENTS.list

  const parseFormInputData = (client) => {
    return {
      first_name: client.data.name && client.data.name.first,
      last_name: client.data.name && client.data.name.last,
      dob:
        (client.data.dob && dateFormatFromIso(client.data.dob, 'MM/DD/yyyy')) ||
        null,
      gender: client.data.gender,
      email:
        client.data.emails &&
        client.data.emails[0] &&
        client.data.emails[0].address,
      phone:
        client.data.phones &&
        client.data.phones[0] &&
        client.data.phones[0].number,
      type:
        client.data.phones &&
        client.data.phones[0] &&
        client.data.phones[0].type,
      address_street: client.data.address && client.data.address.street,
      address_city: client.data.address && client.data.address.city,
      address_state: client.data.address && client.data.address.state,
      address_zip: client.data.address && client.data.address.zip,
      profile_photo_id: client.data.profile_photo_id,
    }
  }

  const parseFormOutputData = (values) => {
    return {
      name: {
        first: values.first_name,
        last: values.last_name,
      },
      dob: dateIsoUtcString(values.dob),
      gender: values.gender,
      emails: values.email && [
        {
          address: values.email,
        },
      ],
      phones: values.phone && [
        {
          number: values.phone,
          type: values.type,
        },
      ],
      address: {
        street: values.address_street,
        city: values.address_city,
        state: values.address_state,
        zip: values.address_zip,
      },
      profile_photo_id: values.profile_photo_id,
      partner_id: values.partner_id,
    }
  }

  const onDeleteAskModalClose = (event) => {
    event.preventDefault()
    setState({ deleteAskModal: false })
  }

  // FIXME “Delete Client” button temporarily hidden
  // const onDeleteAskModal = (e) => {
  //   e.preventDefault()
  //   setState({ deleteAskModal: true })
  // }

  /**
   * Returns the same input object, but with default values.
   */
  const applyDefaultValues = (obj) => {
    const { ...defaultValues } = obj
    !obj.type && (defaultValues.type = '')

    return defaultValues
  }

  // Action handlers
  async function uploadImageFile(file) {
    try {
      const payload = await uploadImage({ variables: { file } })
      return (
        payload &&
        payload.data &&
        payload.data.uploadImage &&
        payload.data.uploadImage._id
      )
    } catch (error) {
      setApiError('Error uploading image')
      setShowErrorModal(true)
      throw new Error(error)
    }
  }

  const onDelete = async (event) => {
    event.preventDefault()

    setState({ loading: true, deleteAskModal: false })
    await deletePatient({
      variables: {
        id: clientId,
      },
    })
    push(CLIENTS.list)
  }

  const onAdd = async (values) => {
    try {
      setShowSavingModal(true)

      const patient = await createPatient({
        variables: {
          data: {
            data: parseFormOutputData(values),
          },
        },
      })

      await delay(1000)
      setShowSavingModal(false)
      setShowSavedModal(true)
      await delay(1000)

      push(
        generatePath(CLIENTS.view, {
          clientId: patient.data.createPatient._id,
        }),
      )
    } catch (error) {
      handleServerError(error)
    }
  }

  const onEdit = async (values) => {
    try {
      setShowSavingModal(true)

      const valuesWithDefaults = applyDefaultValues(values)
      await updatePatient({
        variables: {
          id: clientId,
          data: {
            data: parseFormOutputData(valuesWithDefaults),
          },
        },
      })

      await delay(1000)
      setShowSavingModal(false)
      setShowSavedModal(true)
      await delay(1000)

      push(generatePath(CLIENTS.view, { clientId }))
    } catch (error) {
      handleServerError(error)
    }
  }
  return (
    <>
      <Form
        intl={{
          formatMessage,
        }}
        initialFormData={clientData ? parseFormInputData(clientData) : {}}
        className={style.container}
        onSubmit={clientId ? onEdit : onAdd}
      >
        <Segment className={style.segment}>
          <div className={style.header}>
            {clientId ? (
              <h1>Edit {consumerTitle}</h1>
            ) : (
              <h1>New {consumerTitle}</h1>
            )}
          </div>

          <div className={style.content}>
            <Grid>
              <Grid.Row className={style.paddedBottom}>
                <Grid.Column>
                  <ProfileImageUploadField
                    image={clientPhoto}
                    label="Profile Photo"
                    name="profile_photo_id"
                    uploadFn={uploadImageFile}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  computer={8}
                  tablet={16}
                  className={style.paddedBottom}
                >
                  <InputField
                    autoComplete="no"
                    name="first_name"
                    label="First Name"
                    validate={validate.notEmpty()}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={16}
                  className={style.paddedBottom}
                >
                  <InputField
                    autoComplete="no"
                    name="last_name"
                    label="Last Name"
                    validate={validate.notEmpty()}
                  />
                </Grid.Column>

                <Grid.Column
                  computer={8}
                  tablet={16}
                  className={style.paddedBottom}
                >
                  <DateField
                    name="dob"
                    label="Date of Birth"
                    placeholder="mm/dd/yyyy"
                    validate={validate.combine(
                      validate.notEmpty(),
                      isValidDate,
                      isPastDate,
                      isLeapYearCheck
                    )}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={16}
                  className={style.paddedBottom}
                >
                  <SelectField
                    name="gender"
                    label="Gender"
                    options={[
                      { text: 'Male', value: 'male' },
                      { text: 'Female', value: 'female' },
                    ]}
                    validate={validate.selected()}
                  />
                </Grid.Column>

                <Grid.Column
                  computer={8}
                  tablet={16}
                  className={style.paddedBottom}
                >
                  <InputField
                    autoComplete="no"
                    name="address_street"
                    label="Street Address"
                  />
                </Grid.Column>

                <Grid.Column
                  computer={3}
                  tablet={6}
                  className={style.paddedBottom}
                >
                  <InputField
                    autoComplete="no"
                    name="address_city"
                    label="City"
                  />
                </Grid.Column>

                <Grid.Column
                  computer={3}
                  tablet={6}
                  className={style.paddedBottom}
                >
                  <SelectField
                    className={style.minWidthAuto}
                    name="address_state"
                    label="State"
                    displayValueAsText
                    options={STATES}
                  />
                </Grid.Column>

                <Grid.Column
                  computer={2}
                  tablet={4}
                  className={style.paddedBottom}
                >
                  <ZIPCodeField
                    name="address_zip"
                    label="ZIP"
                    addressFieldNames={[
                      'address_street',
                      'address_city',
                      'address_state',
                    ]}
                  />
                </Grid.Column>

                <Grid.Column
                  computer={4}
                  tablet={10}
                  className={style.paddedBottom}
                >
                  <PhoneInputField
                    autoComplete="no"
                    name="phone"
                    label="Phone Number"
                    placeholder="( ____ ) _____ - _______"
                    country="US"
                  />
                </Grid.Column>
                <Grid.Column
                  computer={4}
                  tablet={6}
                  className={style.paddedBottom}
                >
                  <PhoneNumberTypeSelectField name="type" label="Type" />
                </Grid.Column>

                <Grid.Column
                  computer={8}
                  tablet={16}
                  className={style.paddedBottom}
                >
                  <InputField
                    autoComplete="no"
                    name="email"
                    label="Email Address"
                    validate={validate.combine(
                      validate.isEmail(),
                      validate.notEmpty(),
                    )}
                    type="email"
                    className={style.toLowerCase}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Segment>

        <div className={style.buttonsContainer}>
          {/* {clientData && (
            <Button
              data-test="button-delete-patient"
              onClick={onDeleteAskModal}
              disabled={state.loading}
              variant="basic"
            >
              Delete {consumerTitle}
            </Button>
          )} */}

          <Button
            onClick={() => push(previousPageURL)}
            disabled={state.loading}
            variant="basic"
          >
            Cancel
          </Button>

          <SubmitButton primary>Save</SubmitButton>
        </div>
      </Form>

      {/* Modals */}
      {state.deleteAskModal && (
        <Modal open size="tiny" onClose={onDeleteAskModalClose}>
          <Modal.Header>
            Delete{' '}
            {clientData && clientData.data.name && clientData.data.name.display}
            ?
          </Modal.Header>
          <Modal.Content>
            Do you really want to delete{' '}
            {clientData && clientData.data.name && clientData.data.name.display}{' '}
            from the patient list?
          </Modal.Content>
          <Modal.Actions>
            <Button
              data-test="button-delete-patient-no"
              onClick={onDeleteAskModalClose}
              variant="basic"
            >
              No
            </Button>

            <Button data-test="button-delete-patient-yes" onClick={onDelete}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      <SavingModal
        message="We're saving your client, please wait..."
        open={showSavingModal}
      />
      <SavedModal
        open={showSavedModal}
        message="Your client has been saved"
        onClose={() => setShowSavedModal(false)}
      />
      <ErrorModal
        open={showErrorModal}
        message={apiError}
        onCloseClick={() => {
          setShowSavingModal(false)
          setShowErrorModal(false)
        }}
      />
    </>
  )
}
