import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'

// Utilities
import { camelizeKeys } from 'humps'
import capitalize from '../../../utils/capitalize'
import get from 'lodash/get'

// Styling
import style from './CreateLabOrderModal.module.css'

// Components
import { Modal, Button } from '@labsavvyapp/ui-components'
import { Grid, Dropdown, Popup, Message } from 'semantic-ui-react'
import ClientSearch from '../../../pages/LabReports/New/ClientSearch/ClientSearch'

// Constants
import {
  ORDER_TYPE,
  DEFAULT_PROJECT,
  LAB_TYPE,
} from '../../../pages/Clients/List/constants'
import { LAB_REPORTS, ORDERS_SENT } from '../../../config/routes'

// Queries and Mutations
import { GetMe } from '../../../graphql/user/queries'
import { ListPartnerProjectProviders } from '../../../graphql/providers/queries'

export default function CreateLabOrderModal({
  open,
  onCloseClick,
  editable,
  clearChanges,
}) {
  const { push } = useHistory()
  const { pathname } = useLocation()

  // Params: URL Queries
  const { clientId } = useParams()

  // Query: Get current provider
  const { data: userData } = useQuery(GetMe)
  const parsedUserData = camelizeKeys(userData)
  const consumerTitle = capitalize(
    get(parsedUserData, 'getMe.project.consumersTitle', 'Client'),
  )

  // Query: Get Labs by current Provider>Project
  const { data: providersData } = useQuery(ListPartnerProjectProviders, {
    variables: {
      projectId: userData?.getMe?.project?._id || DEFAULT_PROJECT,
    },
  })

  // Dropdown data: Choose order type
  const orderTypeOptions = ORDER_TYPE.options.map((item) => ({
    text: item.text,
    value: item.value,
    key: item.value + '_' + item.key,
  }))

  // Dropdown data: Choose a laboratory
  const laboratoryOptions = providersData?.listPartnerProjectProviders?.map(
    ({ provider }) => ({
      value: provider?._id,
      text: provider?.name,
      key: provider?._id + '_' + provider?.key,
    }),
  )

  // Dropdown data: Choose a lab type
  const labTypeOptions = LAB_TYPE.options.map((item) => ({
    text: item.text,
    value: item.value,
    key: item.value + '_' + item.key,
  }))

  // Handlers
  const [selectedClient, setSelectedClient] = useState(clientId)
  const handleClientChange = (client) => {
    const client_id = client ? client.id : selectedClient
    setSelectedClient(client_id)
  }
  const [orderType, setOrderType] = useState()
  const handleOrderTypeChange = (_, { value }) => {
    let orderType = ORDER_TYPE.options.find((type) => {
      if (type.value === value) {
        return type
      }
    })
    setOrderType(orderType)
  }

  const [providerId, setProviderId] = useState()
  const handleLaboratoryChange = (_, { value }) => {
    let provider = laboratoryOptions.find((lab) => {
      if (lab.value === value) {
        return lab
      }
    })
    setProviderId(provider)
  }

  const [labType, setLabType] = useState()
  const handleLabTypeChange = (_, { value }) => {
    setLabType(value)

    let labType = LAB_TYPE.options.find((type) => {
      if (type.value === value) {
        return type
      }
    })
    setLabType(labType)
  }

  const handleProceedLabOrder = () => {
    //reset data
    localStorage.removeItem('labOrder')
    localStorage.removeItem('createLabOrder')
    sessionStorage.removeItem('new_lab_order')

    localStorage.setItem(
      'createLabOrder',
      JSON.stringify({
        clientId: selectedClient,
        provider: providerId,
        orderType: orderType,
        labType: labType,
      }),
    )

    const orderSentUrls = () => {
      push({
        pathname:
          labType.value === '1'
            ? `${ORDERS_SENT.newExisting}`
            : `${ORDERS_SENT.newCustom}`,
      })
    }

    switch (pathname) {
      case ORDERS_SENT.base:
      case ORDERS_SENT.newExisting:
      case ORDERS_SENT.newCustom:
        orderSentUrls()
        break

      default:
        push({
          pathname:
            labType.value === '1'
              ? `${LAB_REPORTS.newExisting}`
              : `${LAB_REPORTS.newCustom}`,
        })
        break
    }

    // clear changes for these scenarios
    if (clearChanges) {
      if (editable.providerId.value !== providerId.value) {
        clearChanges()
      } else if (editable.labType.value !== labType.value) {
        clearChanges()
      }
    }
    onCloseClick()
  }

  const ErrorMessage = () => {
    let message = []

    if (!selectedClient) {
      message.push(`${consumerTitle.toLocaleLowerCase()}`)
    }
    if (!providerId) {
      message.push(`laboratory`)
    }

    if (!orderType) {
      message.push(`order type`)
    }
    if (!labType) {
      message.push(`lab type`)
    }

    const formatMessage = (errorMessage) => {
      let formattedMessage = ''
      errorMessage.forEach((item, index) => {
        if (index === errorMessage.length - 2) {
          formattedMessage += item + ', and '
        } else if (index === errorMessage.length - 1) {
          formattedMessage += item + ''
        } else {
          formattedMessage += item + ', '
        }
      })
      return formattedMessage
    }

    if (message.length > 0) {
      return (
        <Message negative>
          <p>
            Please select a <b>{formatMessage(message)}</b> to proceed
          </p>
        </Message>
      )
    } else {
      return null
    }
  }

  const isDirty = () => {
    return (
      Boolean(selectedClient) &&
      Boolean(providerId) &&
      Boolean(labType) &&
      Boolean(orderType)
    )
  }

  // State Management
  useEffect(() => {
    if (editable) {
      setSelectedClient(editable.clientId)
      setProviderId(editable.providerId)
      setOrderType(editable.orderType)
      setLabType(editable.labType)
    }
  }, [editable])

  useEffect(() => {
    //reset data on first load
    if (clearChanges) {
      clearChanges()
    } else {
      localStorage.removeItem('createLabOrder')
    }
  }, [])

  return (
    <Modal className={style.modal} open={open} onCloseClick={onCloseClick}>
      <div className={style.modalHeader}>New Lab Order</div>
      <Grid columns="equal">
        {/* Patient */}
        {!clientId && (
          <Grid.Row>
            <Grid.Column>
              <div>Search {consumerTitle?.toLocaleLowerCase()}:</div>
            </Grid.Column>
            <Grid.Column>
              <ClientSearch
                filters={{ id: selectedClient }}
                onSelect={handleClientChange}
              />
            </Grid.Column>
          </Grid.Row>
        )}

        {/* Laboratory */}
        <Grid.Row>
          <Grid.Column tablet={8}>
            <div>Choose a laboratory:</div>
          </Grid.Column>
          <Grid.Column tablet={8}>
            {laboratoryOptions?.length > 0 ? (
              <div>
                <Dropdown
                  fluid
                  selection
                  value={providerId?.value}
                  options={laboratoryOptions}
                  onChange={handleLaboratoryChange}
                  placeholder="Select a Laboratory"
                />
              </div>
            ) : (
              <Popup
                header="Please contact your LabSavvy Administrator:"
                content="No labs enabled"
                position="right center"
                trigger={
                  <div>
                    <Dropdown
                      fluid
                      selection
                      disabled
                      options={laboratoryOptions}
                    />
                  </div>
                }
              />
            )}
          </Grid.Column>
        </Grid.Row>

        {/* Order Type */}
        <Grid.Row>
          <Grid.Column tablet={8}>
            <div>Choose order type:</div>
          </Grid.Column>
          <Grid.Column tablet={8}>
            <div>
              <Dropdown
                fluid
                selection
                placeholder="Select an order type"
                options={orderTypeOptions}
                onChange={handleOrderTypeChange}
                value={orderType?.value}
              />
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column tablet={8}>
            <div>Choose a lab type:</div>
          </Grid.Column>
          <Grid.Column tablet={8}>
            <div>
              <Dropdown
                fluid
                selection
                placeholder="Select a lab type"
                options={labTypeOptions}
                onChange={handleLabTypeChange}
                value={labType?.value}
              />
            </div>
          </Grid.Column>
        </Grid.Row>

        <ErrorMessage />

        <div className={style.modalButtonsContainer}>
          <Button
            data-test="button-submit-order"
            onClick={handleProceedLabOrder}
            disabled={!isDirty()}
          >
            Proceed
          </Button>
          <Button
            data-test="cancel-button"
            variant="basic"
            onClick={onCloseClick}
          >
            Cancel
          </Button>
        </div>
      </Grid>
    </Modal>
  )
}
