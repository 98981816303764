import React from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'
import { Link, generatePath } from 'react-router-dom'
import get from 'lodash/get'
import { ListRow } from '@labsavvyapp/ui-components'

import style from './LabReportList.module.css'
import { LAB_REPORTS } from '../../../config/routes'
import { capitalize } from 'lodash'

export default function ReportRow({ data }) {
  const { id: labReportId } = data
  const status = get(data, 'status')

  return (
    <ListRow
      className={classnames(style.listRow, {
        [style.unreleased]: data.hasUnreleasedResults,
      })}
      as={Link}
      to={{
        pathname: generatePath(LAB_REPORTS.view, { labReportId }),
        state: { prevPath: window.location.pathname },
      }}
      bordered={false}
    >
      <span className={style.user} data-test="report-client">
        {get(data, 'patient.data.name.display')}
      </span>
      <span className={style.accession} data-test="accession-number">
        {data?.fillerId}
      </span>
      <span className={style.orderid} data-test="order-id">
        LS-{data?.shortId}
      </span>
      <span className={style.reportName} data-test="report-name">
        {data.name}
      </span>
      <span className={style.organization}>{get(data, 'provider.name')}</span>
      <span className={style.ordered}>
        {data.createdAt && format(new Date(data.createdAt), 'MM/dd/yyyy')}
      </span>
      <div className={style.status}>
        <span
          className={classnames('ui', 'label', {
            violet: status === 'ordered',
            blue: status === 'started',
            grey: status === 'partial',
            green: status === 'completed',
            pink: status === 'final',
          })}
        >
          {capitalize(get(data, 'status').replace(/_/g, ' '))}
        </span>
      </div>
      <span className={style.reviewed}>
        {data?.resultReviewed?.date
          ? format(new Date(data.resultReviewed.date), 'MM/dd/yyyy')
          : '-'}
      </span>
      <span className={style.viewed}>
        {data?.resultViewed?.date
          ? format(new Date(data.resultViewed.date), 'MM/dd/yyyy')
          : '-'}
      </span>
    </ListRow>
  )
}
