import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useQuery } from '@apollo/react-hooks'

// Utilities
import { camelizeKeys } from 'humps'
import {
  fetchNext,
  hasMore,
  isEmpty,
  updateURLParameter,
} from '@labsavvyapp/ui-components/lib/utils'
import { useDidUpdateEffect } from '../../../utils/custom-hooks'
import capitalize from '../../../utils/capitalize'
import get from 'lodash/get'

// Styling
import style from './OrderSentListPage.module.css'
import listStyle from './OrderSentList.module.css'

// Components
import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  ListEmpty,
} from '@labsavvyapp/ui-components'
import ListPageHeader from '../../../components/ListPageHeader/ListPageHeader'
import OrderSentListRow from './OrderSentListRow'
import CreateLabOrderModal from '../../../components/Modals/CreateLabOrderModal/CreateLabOrderModal'

// Constants
import { ORDERS_SENT } from '../../../config/routes'
import { SORT_OPTIONS } from './constants'

// Queries and Mutations
import { ListLabOrderProducts } from '../../../graphql/products/queries'
import { GetMe } from '../../../graphql/user/queries'

function getQueryVariables(sortBy, search) {
  const variables = {
    limit: 30,
    sort: {},
    filter: {},
    page: 1,
  }

  switch (sortBy) {
    case SORT_OPTIONS.nameAsc.key:
      variables.sort.name = SORT_OPTIONS.nameAsc.value
      break
    case SORT_OPTIONS.nameDesc.key:
      variables.sort.name = SORT_OPTIONS.nameDesc.value
      break

    case SORT_OPTIONS.dateAsc.key:
      variables.sort.created_at = SORT_OPTIONS.dateAsc.value
      break
    case SORT_OPTIONS.dateDesc.key:
      variables.sort.created_at = SORT_OPTIONS.dateDesc.value
      break

    case SORT_OPTIONS.statusAsc.key:
      variables.sort.status = SORT_OPTIONS.statusAsc.value
      break
    default:
      break
  }

  if (search) {
    variables.filter = { name: search }
  }

  return variables
}

export default function OrderSentListPage() {
  const { push } = useHistory()
  const { search: locationSearch } = useLocation()

  const query = new URLSearchParams(locationSearch)
  const searchParameter = query.get('search') || ''
  const sortByParameter = query.get('sort') || SORT_OPTIONS.dateAsc.key

  const [search, setSearch] = useState(searchParameter)
  const [sortBy, setSortBy] = useState(sortByParameter)
  const [partnerId, setPartnerId] = useState(null)
  const [projectId, setProjectId] = useState(null)

  const { data, loading, fetchMore, refetch } = useQuery(ListLabOrderProducts, {
    notifyOnNetworkStatusChange: true,
    variables: { ...getQueryVariables(sortByParameter, searchParameter) },
  })
  const labOrdersData = camelizeKeys(data)

  // Fetch user information
  const { data: userData } = useQuery(GetMe)
  const parsedUserData = camelizeKeys(userData)
  const consumerTitle = capitalize(
    get(parsedUserData, 'getMe.project.consumersTitle', 'Client'),
  )
  const partner = get(parsedUserData, 'getMe.partner.id', null)
  const project = get(parsedUserData, 'getMe.project.id', null)
  const showNewLabOrderButton =
    parsedUserData &&
    !get(parsedUserData, 'getMe.capabilities.isReadOnlyPartnerManager')

  if (partnerId !== partner || projectId !== project) {
    setPartnerId(partner)
    setProjectId(project)
  }

  useEffect(() => {
    refetch({
      page: 1,
      ...getQueryVariables(sortBy, search),
    })
  }, [sortBy, search, refetch])

  // Refetch data if partner or project change
  useDidUpdateEffect(() => {
    refetch({
      page: 1,
      ...getQueryVariables(sortBy, search),
    })
  }, [partnerId, projectId])

  const [showClientModal, setShowClientModal] = useState(false)

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    setSortBy(value)
    push(`${ORDERS_SENT.base}?${urlParams}`)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    setSearch(value)
    push(`${ORDERS_SENT.base}?${urlParams}`)
  }

  function handleNewLabOrderClick() {
    setShowClientModal(true)
  }

  const getNewLabOrderButton = () => {
    return (
      showNewLabOrderButton && {
        label: 'New Lab Order',
        click: handleNewLabOrderClick,
      }
    )
  }

  // List items
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (labOrdersData) {
    items = labOrdersData.listLabOrderProducts.products
    pagination.page = labOrdersData.listLabOrderProducts.page
    pagination.pages = labOrdersData.listLabOrderProducts.pages
    pagination.total = labOrdersData.listLabOrderProducts.total
  }

  return (
    <div className={style.pageContainer}>
      <ListPageHeader
        title={`Orders Sent to ${consumerTitle}`}
        ctaButton={getNewLabOrderButton()}
        sort={{
          sortOptions: Object.keys(SORT_OPTIONS).map((option) => ({
            text: SORT_OPTIONS[option].text,
            value: SORT_OPTIONS[option].key,
            key: SORT_OPTIONS[option].key,
          })),
          sortBy,
          onChange: handleSortChange,
        }}
        search={{
          placeholder: 'Search Reports',
          onChange: handleSearchChange,
          text: search,
        }}
      />

      <List>
        <ListHeader
          className={listStyle.listHeader}
          columns={[
            { name: 'Order Name', className: listStyle.reportName },
            { name: 'Organization', className: listStyle.organization },
            { name: consumerTitle, className: listStyle.user },
            { name: 'Ordered', className: listStyle.ordered },
            { name: 'Payment Status', className: listStyle.status },
            // { name: 'Viewed', className: listStyle.viewed },
          ]}
        />

        <InfiniteScrollList
          scrollableTarget="lab-report-list"
          containerClassName={style.infiniteScrollContainer}
          dataLength={items.length}
          hasMore={hasMore(pagination)}
          next={() =>
            fetchNext('listLabOrderProducts', 'products', {
              page: pagination.page,
              fetchMore,
            })
          }
        >
          {!isEmpty(items) &&
            items.map((item) => <OrderSentListRow key={item.id} data={item} />)}

          <ListLoader
            fetched={items.length}
            total={pagination.total}
            loading={loading}
          />

          {!loading && isEmpty(items) && (
            <ListEmpty
              message={`No orders sent to ${consumerTitle.toLocaleLowerCase()} found.`}
            />
          )}
        </InfiniteScrollList>
      </List>

      <CreateLabOrderModal
        open={showClientModal}
        onCloseClick={() => setShowClientModal(false)}
      />
    </div>
  )
}
/* eslint-enable */
