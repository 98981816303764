import React from 'react'

const Quest = (provider) => (
  <ol>
    <li>
      <b>Schedule</b> an appointment with a nearby{' '}
      <a
        href="https://appointment.questdiagnostics.com/patient/appointment"
        target="_blank"
        rel="noopener noreferrer"
      >
        {provider.text} location
      </a>
      <ol type="a" style={{ marginTop: 10 }}>
        <li>
          Walk-in testing may be available but could require a substantial wait
          time. We recommend scheduling your appointment in advance and for
          early morning if you need to fast.{' '}
        </li>
        <li style={{ marginTop: 8 }}>
          Once in the Quest application, select "All Other Tests".{' '}
        </li>
        <li style={{ marginTop: 8 }}>
          On their "Who’s Visiting" screen, in the "Would you like to provide
          insurance for the person being tested?" question, select the 2nd
          option: "The person being tested will bring it to the appointment." **
        </li>
        <li style={{ marginTop: 8 }}>
          Continue to navigate through their screens.
        </li>
      </ol>
      <p style={{ marginTop: 10, marginBottom: 10 }}>
        **Please note that although you’re selecting Quest insurance options to
        schedule your appointment, your order is prepaid, so no insurance /
        payment information is required at time of service.
      </p>
    </li>
    <li>
      <b>It’s recommended that you print</b> your lab order. Try to bring it
      with you to the lab. Remember, LabSavvy orders are prepaid, so do not make
      any payments to Quest Diagnostics and do not submit your order as a claim
      to your insurance provider.
    </li>
    <li style={{ marginTop: 10 }}>
      <b>Prepare</b> for your blood draw. Please check with your provider or
      with <b>{provider.text}</b> to see if you need to fast. If so, follow the{' '}
      <a
        href="https://www.questdiagnostics.com/patients/get-tested/prepare/fasting"
        target="_blank"
        rel="noopener noreferrer"
      >
        "Fasting for lab tests instructions"
      </a>{' '}
      on the Quest website.
    </li>
  </ol>
)

export default Quest
