export const SORT_OPTIONS = Object.freeze({
  defaultSortOption: 'nameAZ',
  options: [
    {
      text: 'Name A-Z',
      queryStringValue: 'nameAZ',
      key: 'name',
      value: 'asc',
    },
    {
      text: 'Name Z-A',
      queryStringValue: 'nameZA',
      key: 'name',
      value: 'desc',
    },
  ],
})

export const ORDER_TYPE = Object.freeze({
  defaultOrderTypeOption: 'direct_to_lab',
  options: [
    {
      text: 'Submit directly to Lab',
      label: 'Lab Contract Price',
      queryStringValue: 'direct_to_lab',
      key: 'order_type',
      value: '1',
    },
    {
      text: 'Send to Patient for Purchase',
      label: 'Patient Retail Price',
      queryStringValue: 'patient_to_purchase',
      key: 'order_type',
      value: '2',
    },
  ],
})
export const LAB_TYPE = Object.freeze({
  defaultLabTypeOption: 'existing_package',
  options: [
    {
      text: 'Existing Package',
      queryStringValue: 'existing_package',
      key: 'lab_type',
      value: '1',
    },
    {
      text: 'Custom Package',
      queryStringValue: 'custom_package',
      key: 'lab_type',
      value: '2',
    },
  ],
})

export const DEFAULT_PROJECT = '5e53da61f5935b001ab1a0f4'
