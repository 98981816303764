import React from 'react'
import { Search } from 'semantic-ui-react'
import compact from 'lodash/compact'

import style from './index.module.css'
import debounce from '../../../utils/debounce'
import ContactCard from '../ContactCard'

export default function ContactList({
  contactList,
  // loading,
  // loader,
  onContactClick,
  onSearchChange,
  selectedContact,
  searchPlaceHolder = 'Search',
}) {
  const debouncedOnSearchChange = debounce(onSearchChange, 300)
  return (
    <section className={style.container}>
      <Search
        className={style.search}
        onSearchChange={(_, { value: search }) =>
          debouncedOnSearchChange(search)
        }
        placeholder={searchPlaceHolder}
        showNoResults={false}
      />
      <ul className={style.list}>
        {compact(contactList).map((contact) => (
          <li key={`contact-list-${contact.id}`} className={style.itemItem}>
            <ContactCard
              contact={contact}
              unread={0}
              onClick={onContactClick}
              selected={selectedContact?.id === contact.id}
            />
          </li>
        ))}
      </ul>
    </section>
  )
}
