import React from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'
import get from 'lodash/get'
import { ListRow } from '@labsavvyapp/ui-components'

import style from './OrderSentList.module.css'

export default function OrderSentListRow({ data }) {
  return (
    <ListRow
      className={classnames(style.listRow, {
        [style.unreleased]: data.hasUnreleasedResults,
      })}
      bordered={false}
    >
      <span className={style.reportName} data-test="report-name">
        {data.name}
      </span>
      <span className={style.organization}>{get(data, 'partnerName')}</span>
      <span className={style.user} data-test="report-client">
        {get(data, 'patientName')}
      </span>
      <span className={style.ordered}>
        {data.createdAt && format(new Date(data.createdAt), 'MM/dd/yyyy')}
      </span>
      <span className={style.status}>
        {get(data, 'stripeReferenceStatus') || get(data, 'orderStatus')}
      </span>
      {/* <span
        className={style.viewed}
        alt={
          data.firstViewed &&
          format(new Date(data && data.firstViewed), 'MM/dd/yyyy')
        }
      >
        <Icon
          name="checkmark"
          className={classnames({
            [style.checkGreen]: data.firstViewed,
            [style.checkGrey]: !data.firstViewed,
          })}
        />
      </span> */}
    </ListRow>
  )
}
