export const SORT_OPTIONS = {
  dateAsc: { text: 'Newest to Oldest', value: 'desc', key: 'dateDesc' },
  dateDesc: { text: 'Oldest to Newest', value: 'asc', key: 'dateAsc' },
  nameAsc: {
    text: 'First Name A-Z',
    value: 'asc',
    key: 'nameAsc',
  },
  nameDesc: {
    text: 'First Name Z-A',
    value: 'desc',
    key: 'nameDesc',
  },
  statusAsc: { text: 'Status', value: 'asc', key: 'statusAsc' },
}

export const FILTER_OPTIONS = {
  allResults: { text: 'All', value: 'all', key: 'allResults' },
  resultViewed: { text: 'Viewed', value: 'viewed', key: 'resultViewed' },
  resultNotViewed: {
    text: 'Not Viewed',
    value: 'notViewed',
    key: 'resultNotViewed',
  },
}
