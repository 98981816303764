import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useLocation, generatePath, Prompt } from 'react-router-dom'
import { Dropdown, Message, Image } from 'semantic-ui-react'
import {
  Button,
  useSavingModals,
  NameInitials,
} from '@labsavvyapp/ui-components'
import { LAB_REPORTS, ORDERS_SENT } from '../../../config/routes'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  CreateLabOrder,
  CreateLabOrderProduct,
} from '../../../graphql/lab-orders/mutations'
import { GetPatient } from '../../../graphql/clients/queries.js'
import useLocalReducer from '../../../hooks/useLocalReducer'
import style from './ReviewLabOrder.module.css'

import Quest from './TemplateQuest'
import Sunrise from './TemplateSunrise'
import ReproSource from './TemplateReproSource'
import Purchase from './HowToPurchase'
import Footer from './Footer'

const ReviewLabOrder = () => {
  const { pathname } = useLocation()
  const { clientId, provider, orderType, labType } = JSON.parse(
    localStorage.getItem('createLabOrder'),
  )
  const [reusabilityPackageType, setReusabilityPackageType] = useState(0)
  const [state, , clearState] = useLocalReducer(
    'new_lab_order',
    (state, action) => ({ ...state, ...action }),
    {},
  )

  const [metadataInfo, setMetadataInfo] = useState(state?.metadata)
  const [orderName, setOrderName] = useState(
    state?.orderName || state?.packageSearch,
  )
  const [selectedPanels, setSelectedPanels] = useState(state?.selectedPanels)
  const [selectedCompendiumPanels, setSelectedCompendiumPanels] = useState(
    state?.selectedCompendiumPanels,
  )
  const [reviewSubmitData, setReviewSubmitData] = useState(
    state?.reviewSubmitData,
  )

  useEffect(() => {
    const {
      data,
      clientId,
      metadata,
      orderName,
      packageSearch,
      selectedPanels,
      selectedCompendiumPanels,
    } = JSON.parse(localStorage.getItem('labOrder'))
    setMetadataInfo(metadata)
    // setClientId(clientId)
    setOrderName(orderName || packageSearch)
    setSelectedPanels(selectedPanels)
    setSelectedCompendiumPanels(selectedCompendiumPanels)
    setReviewSubmitData(data)
  }, [])

  const { data: patient } = useQuery(GetPatient, {
    variables: {
      id: clientId,
    },
  })

  const reusabilityPackageOptions = [
    { text: 'No, this is a single-use package', value: 0 },
    {
      text: 'Yes, save this as a package for my future use',
      value: 1,
    },
  ]

  const { push, goBack } = useHistory()

  const handleReusabilityPackageType = async (_, { value }) => {
    setReusabilityPackageType(value)
  }

  // Form handlers
  const [createLabOrder] = useMutation(CreateLabOrder)
  const [createLabOrderProduct] = useMutation(CreateLabOrderProduct)

  // Progress Modals
  //* lab order is sent to lab
  const [modals, { showModals }] = useSavingModals({
    savingMessage: 'Your order is being submitted, please wait...',
    savedMessage: 'Your order has been submitted.',
    callback: async ({ data }) => {
      const { data: createLabOrderResponse } = await createLabOrder({
        variables: { data },
      })

      return createLabOrderResponse
    },
    onError: (error) => `${error}`,
    onSuccess: ({ createLabOrder }) => {
      localStorage.removeItem('labOrder')
      localStorage.removeItem('createLabOrder')
      push(
        generatePath(LAB_REPORTS.view, {
          labReportId: createLabOrder._id,
        }),
      )
    },
  })

  const onSubmit = async () => {
    let dataFinal = state.reviewSubmitData
    dataFinal.is_package_reusable = !!+reusabilityPackageType
    dataFinal.comments = {
      internal: internalComment,
      reporting: reportingComment,
    }
    showModals({ data: dataFinal })
  }

  //* lab order is sent to patient for purchase
  const [modalsEmailSubmission, { showModals: createViaEmail }] =
    useSavingModals({
      savingMessage: 'Your order is sent to patient, please wait...',
      savedMessage: 'Your order has been sent to patient.',
      callback: async ({ data }) => {
        const { data: createLabOrderProductResponse } =
          await createLabOrderProduct({
            variables: { data },
          })
        return createLabOrderProductResponse
      },
      onError: (error) => `${error}`,
      onSuccess: () => {
        clearState()
        localStorage.removeItem('labOrder')
        localStorage.removeItem('createLabOrder')
        setTimeout(function () {
          push(ORDERS_SENT.base)
        }, 0)
      },
    })

  const sendByEmail = async () => {
    let data = reviewSubmitData
    data.name = orderName
    data.is_package_reusable = !!+reusabilityPackageType
    data.patient_id = reviewSubmitData?.patient_id
    data.order_codes = reviewSubmitData?.order_items_data?.map(
      (item) => item.order_code,
    )
    data.comments = {
      internal: internalComment,
      reporting: reportingComment,
    }
    createViaEmail({ data })
  }

  const profilePhoto = patient?.getPatient?.data?.profile_photo?.url

  const [reportingComment, setReportingComment] = useState()
  const [internalComment, setInternalComment] = useState()
  const handleCommentValidation = (event) => {
    const value = event.target.value.replace(/[^A-Za-z0-9()+\-\r\n\s]/g, '')
    event.target.name === 'reportingComment'
      ? setReportingComment(value)
      : event.target.name === 'internalComment' && setInternalComment(value)
  }

  // [CL-592] email content handle base on Lab
  // but should be refactored once integrated in the admin settings
  const emailContent = (provider) => {
    if (provider.text.search(/reprosource/i) > -1) {
      return <ReproSource provider={provider} />
    }

    if (provider.text.search(/sunrise/i) > -1) {
      return <Sunrise provider={provider} />
    }

    return <Quest provider={provider} />
  }

  return (
    <div className={style.pageContainer}>
      <Helmet>
        <title>Review Order</title>
      </Helmet>
      <div className={style.pageTitle}>
        <h2>Review Order</h2>
      </div>
      <div className={style.contentContainer}>
        <div className={style.contentHeader}>
          Review the following order before submitting.
        </div>
        <div className={style.contentBody}>
          <div className={style.contentBodyLeft}>
            <div className={style.orderInformation}>
              {profilePhoto ? (
                <Image src={profilePhoto} size="small" circular />
              ) : patient?.getPatient?.data.name.first &&
                patient?.getPatient?.data.name.last ? (
                <NameInitials
                  className={style.profilePhoto}
                  firstName={patient?.getPatient?.data.name.first}
                  lastName={patient?.getPatient?.data.name.last}
                  size={110}
                />
              ) : (
                ''
              )}
              <div className={style.orderInfoListItem}>
                <div>Patient: </div>
                <div>{`${patient?.getPatient?.data?.name?.first || ''} ${
                  patient?.getPatient?.data?.name?.last || ''
                }`}</div>
              </div>
              <div className={style.orderInfoListItem}>
                <div>Order Name: </div>
                <div>{orderName || reviewSubmitData.name}</div>
              </div>
              <div className={style.orderInfoListItem}>
                <div>Laboratory: </div>
                <div>{provider.text}</div>
              </div>
              <div className={style.orderInfoListItem}>
                <div>Tests: </div>
                <div>
                  {selectedPanels?.map((item) => (
                    <div key={item.code} className={style.testsListItem}>
                      <div>{item.code} </div>
                      <div>{item.name}</div>
                    </div>
                  ))}
                  {selectedCompendiumPanels?.map((item) => (
                    <div key={item.code} className={style.testsListItem}>
                      <div>{item.code} </div>
                      <div>{item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.orderInfoListItem}>
                <div>Lab Type: </div>
                <div>{labType.text}</div>
              </div>
              <div className={style.orderInfoListItem}>
                <div>Order Type: </div>
                <div>{orderType.text}</div>
              </div>
              <div className={style.orderInfoListItem}>
                <div>Price: </div>
                <div>{metadataInfo?.total_price}</div>
              </div>
            </div>
          </div>
          <div className={style.contentBodyCenter}>
            {labType.value === '2' && (
              <>
                <div className={style.sectionTitle}>
                  <h3>Note for Provider:</h3>
                </div>
                <br></br>
                <div>
                  Would you like to save{' '}
                  <b>&quot;{reviewSubmitData.name}&quot;</b> as an Existing
                  Package, to use again in the future?
                </div>
                <br></br>
                <div>
                  <Dropdown
                    fluid
                    selection
                    defaultValue={reusabilityPackageOptions[0].value}
                    onChange={handleReusabilityPackageType}
                    placeholder="Choose one the continue"
                    options={reusabilityPackageOptions}
                  />
                </div>
              </>
            )}
            <div className={style.sectionTitle} style={{ marginTop: 20 }}>
              <h3>Internal Comment</h3>
              {/* <div className={style.addNotesButton}>
                <ButtonPlus />
              </div> */}
            </div>
            <textarea
              className={style.commentsField}
              placeholder="Add comments here"
              value={internalComment}
              name="internalComment"
              maxLength={999}
              onChange={handleCommentValidation}
            ></textarea>

            <div className={style.sectionTitle}>
              <h3>Reporting Comment</h3>
              {/* <div className={style.addNotesButton}>
                <ButtonPlus />
              </div> */}
            </div>
            <textarea
              className={style.commentsField}
              placeholder="Add comments here"
              value={reportingComment}
              name="reportingComment"
              maxLength={999}
              onChange={handleCommentValidation}
            ></textarea>
          </div>
          <div className={style.contentBodyRight}>
            {orderType.value === '1' && (
              <div className={style.orderSubmissionTypeLab}>
                <Message info>
                  <p>
                    Email Notification that the patient will receive after
                    submitting the order to the lab
                  </p>
                </Message>
                <p>
                  Hello <b>{patient?.getPatient?.data?.name?.display}</b>,
                </p>
                <p>
                  Thank you for your order! Here are some important next steps
                  for completing your lab testing order:{' '}
                </p>

                {emailContent(provider)}
                <Footer />
              </div>
            )}

            {orderType.value === '2' && (
              <div className={style.orderSubmissionTypeLab}>
                <Message info>
                  <p>
                    Email Notification for the patient when order is sent to
                    them for purchase
                  </p>
                </Message>
                <div>
                  <p>
                    Hello <b>{patient?.getPatient?.data?.name?.display}</b>,
                  </p>
                  <p>
                    The lab tests your provider ordered are now available for
                    purchase. You may do this via either the LabSavvy web portal
                    or through the LabSavvy app.
                  </p>

                  <Purchase style={style} />

                  <b className={style.emailHeader}>After your purchase</b>
                  <span className={style.emailContent}>
                    , follow these next steps for completing your lab testing
                    order:
                  </span>

                  {emailContent(provider)}
                  <Footer />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={style.buttonsContainer}>
        <Button
          className={style.editButton}
          variant="basic"
          onClick={() => goBack()}
        >
          Edit Order
        </Button>

        <div>
          <Button
            className={style.cancelButton}
            variant="basic"
            onClick={() => {
              if (pathname.includes(LAB_REPORTS.base)) {
                push(LAB_REPORTS.base)
              } else {
                push(ORDERS_SENT.base)
              }
            }}
          >
            Cancel
          </Button>
          {orderType.value === '1' && (
            <Button
              className={style.submitButton}
              variant="primary"
              onClick={onSubmit}
            >
              Submit Order
            </Button>
          )}
          {orderType.value === '2' && (
            <Button
              className={style.submitButton}
              variant="primary"
              onClick={sendByEmail}
            >
              Send order to patient
            </Button>
          )}
        </div>
      </div>
      {/* Modals */}
      {modals}
      {modalsEmailSubmission}
      {/* Unsaved warning */}
      <Prompt
        message={(location) => {
          return location.pathname.startsWith(LAB_REPORTS.new) ||
            location.pathname.match(/lab-reports\/[\s\S]{1,24}\/view/) ||
            !window.sessionStorage.getItem('new_lab_order')
            ? true
            : 'You have unsaved changes! Are you sure you want to leave?'
        }}
      />
    </div>
  )
}

export default ReviewLabOrder
