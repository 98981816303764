import React from 'react'

const Footer = () => (
  <>
    <p style={{ marginTop: 10 }}>
      You will receive an email when your results are ready for viewing, usually
      within five days.
    </p>
    <p style={{ marginTop: 10 }}>Thank you,</p>
    <p tyle={{ marginBottom: 10 }}>The LabSavvy Team</p>
  </>
)

export default Footer
