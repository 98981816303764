import React from 'react'

const HowToPurchase = (style) => (
  <>
    <p className={style.emailHeader}>Purchase via the web portal</p>
    <ol>
      <li>
        Access the portal at{' '}
        <a
          href="https://patient.labsavvy.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://patient.labsavvy.com{' '}
        </a>
        and sign in using the credentials you established when you signed up
        using the mobile app.
      </li>
      <li>
        On the left-hand side purple panel, go into the “Shop” icon and click
        the “+” on the lab(s) you want to put into your cart.
      </li>
      <li>
        Review your cart by clicking the “Purse” icon in the upper right-hand
        corner.
      </li>
      <li>Then check out using the prompts.</li>
    </ol>
    <p className={style.emailHeader}>Purchase via the mobile app</p>
    <ol>
      <li>
        Sign into the mobile app using the credentials you established when you
        signed up.
      </li>
      <li>
        On the bottom panel, go into the “Shop” icon and click “Pending for
        Purchase”.
      </li>
      <li>Add to the bag the lab(s) you want to put into your cart.</li>
      <li>
        Review your cart by clicking the “Purse” icon in the upper right-hand
        corner.
      </li>
      <li>Then check out using the prompts.</li>
    </ol>
  </>
)

export default HowToPurchase
