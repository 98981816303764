/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { camelizeKeys } from 'humps'
import { Loader } from '@labsavvyapp/ui-components'
import { useHistory, generatePath } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { format } from 'date-fns'

import { CLIENTS, MESSAGING } from '../../config/routes'

import { useFirebase, useNotification } from '../../hooks/firebase'
import { useMessageList } from '../../hooks/message-list'
import { useContactList } from '../../hooks/contact-list.js'

import PageHeader from '../../components/PageHeader/PageHeader'
import ContactProfile from '../../components/Messaging/ContactProfile'
import Composer from '../../components/Messaging/Composer'
import ContactList from '../../components/Messaging/ContactList'
import MessageList, {
  Sender as SentMessage,
} from '../../components/Messaging/MessageList'
import NotificationPermissionRequired from '../../components/Messaging/NotificationPermissionRequired'
import { isSameDay } from './utils'
import ReceivedMessage from './ReceivedMessage'

import style from './Messaging.module.css'

export default function Messaging({ me: currentUserData }) {
  const { push } = useHistory()
  const currentUser = camelizeKeys(currentUserData)

  // contact list
  const {
    contactList = [],
    loading: loadingContactList,
    refetch: refetchContactList,
  } = useContactList({
    skip: !currentUser?.id,
    variables: {
      mobileUserId: currentUser?.id,
    },
  })

  const [selectedContact, setSelectedContact] = useState()
  const handleContactClick = (contact) => {
    // reset messages
    resetMessages()
    // update url
    push(generatePath(MESSAGING.chat, { selectedContactId: contact.id }))
    // update currently selected contact
    setSelectedContact(contact)
  }

  // set initial selected contact
  useEffect(() => {
    if (contactList.length && !selectedContact)
      handleContactClick(contactList[0])
  }, [contactList])

  const { messaging, refreshToken } = useFirebase({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  })
  const { hasPermission, requestPermission } = useNotification()
  useEffect(() => {
    if (!hasPermission) return
    refreshToken().then((err) => {
      if (err instanceof Error) console.warn('Refresh Token failed', err)
    })
  }, [hasPermission])

  // message list
  const {
    messages,
    loading: loadingMessageList,
    page: messageListPage,
    pages: messageListPages,
    sendMessage,
    resetMessages,
  } = useMessageList(
    {
      messaging,
      receiveMessage: (message) => {},
    },
    {
      skip: !currentUser?.id || !selectedContact?.id,
      variables: {
        to: selectedContact?.id,
      },
    },
  )

  const handleComposedMessage = (message) => {
    const messageObj = {
      sender: currentUser.id,
      to: selectedContact.id,
      message,
    }
    sendMessage(messageObj)
  }

  return (
    <div className={style.container}>
      <PageHeader title="Messages" />
      <div className={style.panelsContainer}>
        {!hasPermission && (
          <NotificationPermissionRequired
            requestPermission={requestPermission}
          />
        )}
        {hasPermission && (
          <>
            <div className={style.contactListContainer}>
              <ContactList
                contactList={contactList}
                loading={loadingContactList}
                onContactClick={handleContactClick}
                onSearchChange={(search) =>
                  refetchContactList({
                    mobileUserId: currentUser.id,
                    search,
                  })
                }
                selectedContact={selectedContact}
              />
            </div>
            <div className={style.conversationContainer}>
              {(loadingContactList || loadingMessageList) && <Loader />}
              {selectedContact?.id && (
                <>
                  <MessageList
                    initialLoad={messageListPage === 1}
                    onNextPage={() => {}}
                    hasMore={messageListPage < messageListPages}
                    scrollToBottom={true}
                    onScrollToBottom={() => {}}
                  >
                    {messages.reduce(
                      (acc, { sender, message, date }, index) => {
                        if (
                          index === 0 ||
                          !isSameDay(date, messages[index - 1].date)
                        ) {
                          acc.push(
                            <div
                              className={style.dateSeparator}
                              key={`date-separator-${format(
                                new Date(date),
                                'dd-mm-yyyy',
                              )}`}
                            >
                              {format(new Date(date), 'MMM d, yyyy')}
                            </div>,
                          )
                        }
                        acc.push(
                          currentUser.id === sender ? (
                            <SentMessage
                              key={uuidv4()}
                              message={message}
                              date={date}
                            />
                          ) : (
                            <ReceivedMessage
                              key={uuidv4()}
                              contact={sender}
                              message={message}
                              date={date}
                            />
                          ),
                        )
                        return acc
                      },
                      [],
                    )}
                  </MessageList>
                  <div className={style.composerContainer}>
                    <Composer
                      disabled={selectedContact?.id ? false : true}
                      onSend={(message) => handleComposedMessage(message)}
                    />
                  </div>
                </>
              )}
            </div>

            <div className={style.userProfileContainer}>
              <ContactProfile
                contact={selectedContact}
                getProfileUrl={(contact) =>
                  generatePath(CLIENTS.view, { clientId: contact.id })
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
/* eslint-enable */
