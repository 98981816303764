import React, { useState, useEffect } from 'react'
import { matchPath, useLocation, useHistory } from 'react-router'
import { Dropdown } from 'semantic-ui-react'
import {
  Header,
  Sidebar,
  Modal,
  Button,
  useSavingModals,
  HeaderDropdown,
  ErrorModal,
} from '@labsavvyapp/ui-components'
import { useQuery, useMutation } from '@apollo/react-hooks'

/// Styles
import style from './MainLayout.module.css'

/// Queries and Mutations
import { ListMyPartners } from '../../graphql/partners/queries.js'
import { ListPatients } from '../../graphql/clients/queries.js'
import { ListLabOrders } from '../../graphql/lab-orders/queries.js'
import {
  SetActivePartner,
  SetActiveProject,
} from '../../graphql/partners/mutations.js'
import { GetMe } from '../../graphql/user/queries.js'
import { WebappListPackages } from '../../graphql/lab-orders/queries.js'

/// Utilities
import _ from 'lodash'
import get from 'lodash/get'
import { camelizeKeys } from 'humps'
import capitalize from '../../utils/capitalize'
import { setCookie } from '../../utils/auth'

/// Assets
import logo from '../../assets//labsavvy-logo.svg'
import laboratoryIcon from '../../assets/icons/laboratory-icon.svg'
import personIcon from '../../assets/icons/person-icon.svg'
import chatIcon from '../../assets/icons/chat-icon.svg'
import orderSentIcon from '../../assets/icons/order-sent-icon.svg'

/// Constants
import {
  HOME,
  SIGN,
  LAB_REPORTS,
  CLIENTS,
  PROFILE,
  MESSAGING,
  ORDERS_SENT,
} from '../../config/routes'

const dropdownItems = [
  {
    name: 'My Profile',
    url: PROFILE.base,
    showWhenLoggedIn: true,
  },
  {
    name: 'Sign Out',
    url: SIGN.out,
    showWhenLoggedIn: true,
    showDivider: true,
  },
  {
    name: 'Sign in',
    url: SIGN.in,
    showWhenLoggedIn: false,
  },
  {
    name: 'Sign up',
    url: SIGN.up,
    showWhenLoggedIn: false,
  },
]

const getSidebarItems = (pathname, me) => {
  const consumerTitle = capitalize(get(me, 'project.consumers_title', 'Client'))
  let items = [
    {
      title: 'Lab Reports',
      icon: laboratoryIcon,
      url: LAB_REPORTS.base,
      active: matchPath(pathname, {
        path: LAB_REPORTS.base,
      }),
    },
    {
      title: `Orders Sent to ${consumerTitle}`,
      icon: orderSentIcon,
      url: ORDERS_SENT.base,
      active: matchPath(pathname, {
        path: ORDERS_SENT.base,
      }),
    },

    {
      title: `${consumerTitle}s`,
      icon: personIcon,
      url: CLIENTS.base,
      active: matchPath(pathname, {
        path: CLIENTS.base,
      }),
    },
  ]

  if (
    me &&
    !me.capabilities.isSuperAdmin &&
    process.env.REACT_APP_USE_CHAT === 'true'
  ) {
    items.push({
      title: 'Messaging',
      icon: chatIcon,
      url: MESSAGING.base,
      active: matchPath(pathname, {
        path: MESSAGING.base,
      }),
    })
  }

  return items
}

export default function MainLayout({ children, loggedIn, me }) {
  const { push } = useHistory()
  const { pathname } = useLocation()

  const partnerId = me && me.partner._id

  const [showSwitchPartnerModal, setShowSwitchPartnerModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [selectedPartner, setSelectedPartner] = useState(null)
  const [hideProjectDropdown, setHideProjectDropdown] = useState()

  // Navigation
  const handleLinkClick = ({ url, onClick }) => {
    if (url) {
      push(url)
    }
    onClick && onClick({ setShowSwitchPartnerModal })
  }

  // Fetch partners list
  const { data: partnersResponse } = useQuery(ListMyPartners)
  const partnersData = camelizeKeys(partnersResponse)

  // Grab the selected partner data through the partner id
  const selectedPartnerData =
    partnersData &&
    partnersData.listMyPartners &&
    partnersData.listMyPartners.partners.find(
      ({ id }) => id === selectedPartner,
    )

  // Map Partner Project options
  const projectsOptions = selectedPartnerData
    ? selectedPartnerData.projects.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      }))
    : []

  // Map Partner options
  const partnersOptions =
    partnersData && partnersData.listMyPartners
      ? partnersData.listMyPartners.partners.map(({ id, name }) => ({
          key: id,
          value: id,
          text: name,
        }))
      : []

  // Update active partner project
  const [setActiveProject, { loading: settingActivePartner }] =
    useMutation(SetActiveProject)

  const handleSwitchProject = async (_, { value }) => {
    try {
      const { data } = await setActiveProject({
        variables: { projectId: value },
        refetchQueries: [
          {
            query: GetMe,
          },
        ],
      })
      sessionStorage.setItem('currentActiveProject', value)
      setCookie(data.setActiveProject.access_token)
    } catch {
      setShowErrorModal(true)
    }
  }

  // Update active partner
  const { refetch: refetchListPackages } = useQuery(WebappListPackages, {
    skip: true,
  })
  const { refetch: refetchListLabOrders } = useQuery(ListLabOrders, {
    skip: true,
  })
  const { refetch: refetchListPatients } = useQuery(ListPatients, {
    skip: true,
  })
  const [setActivePartner] = useMutation(SetActivePartner, {
    onCompleted: ({ setActivePartner }) => {
      // Update cookie with the new access token
      setCookie(setActivePartner.access_token)

      refetchListPackages()
      refetchListLabOrders()
      refetchListPatients()
    },
    refetchQueries: [
      {
        query: GetMe,
      },
    ],
  })

  const [modals, { showModals }] = useSavingModals({
    savedMessage: 'Your Partner has been saved',
    savingMessage: 'Your Partner is being saved.',
    errorMessage: 'Error saving your Partner.',
    callback: ({ partnerId }) => {
      setActivePartner({
        variables: { partnerId },
      })

      setTimeout(() => {
        push(LAB_REPORTS.base)
      }, 400)
    },
  })

  const handleSwitchPartner = (_, { value }) => {
    setSelectedPartner(value)
    handleSavePartner()
  }

  const handleSavePartner = (_, { value }) => {
    setShowSwitchPartnerModal(false)
    showModals({ partnerId: value })
  }

  const preventSwitchProject = () => {
    const notAllowedToSwitchRoutes = [
      LAB_REPORTS.newExisting,
      LAB_REPORTS.newCustom,
      LAB_REPORTS.review,
      ORDERS_SENT.newExisting,
      ORDERS_SENT.newCustom,
      ORDERS_SENT.review,
    ]

    const match = _.includes(notAllowedToSwitchRoutes, pathname)
    setHideProjectDropdown(match)
    return match
  }

  // Set the currently selected partner
  useEffect(() => {
    if (partnerId !== selectedPartner) {
      setSelectedPartner(me && me.partner._id)
    }
  }, [partnerId])

  // Disable Project switch in certain pages (e.g. Lab Order flow)
  useEffect(() => {
    preventSwitchProject()
  }, [pathname])

  const getInitialProject = () => {
    const currentPartner = _.find(partnersData.listMyPartners.partners, {
      id: me.partner._id,
    })
    return currentPartner.projects[0].id
  }

  return (
    <div
      className={style.root}
      style={{
        height: pathname.match(/lab-reports.*view/) ? '' : '100%',
      }}
    >
      <Header
        className={style.header}
        logo={{ image: logo, url: HOME }}
        onLinkClick={() => handleLinkClick({ url: HOME })}
      >
        <>
          <span className={style.label}>Partner:</span>
          <Dropdown
            selection
            search
            placeholder="Partners"
            value={selectedPartner}
            options={partnersOptions}
            onChange={handleSavePartner}
          />
          <span className={style.label}>Project:</span>
          {projectsOptions.length > 0 && (
            <Dropdown
              selection
              search
              className={style.projectsDropdown}
              loading={settingActivePartner}
              disabled={hideProjectDropdown}
              value={
                sessionStorage.getItem('currentActiveProject') ||
                getInitialProject()
              }
              options={projectsOptions}
              onChange={handleSwitchProject}
              defaultValue={me.project._id}
            />
          )}
          <HeaderDropdown
            logged={loggedIn}
            me={me}
            dropdownItems={dropdownItems}
            onLinkClick={handleLinkClick}
          />
        </>
      </Header>

      <div className={style.contentHolder}>
        <Sidebar
          items={getSidebarItems(pathname, me)}
          onItemClick={(url) => handleLinkClick({ url })}
        />
        <div className={style.content}>{children}</div>
      </div>

      {/* Modals */}
      <Modal
        className={style.switchPartnerModal}
        title="Switch Partner"
        open={showSwitchPartnerModal}
        onCloseClick={() => setShowSwitchPartnerModal(false)}
        testId="modal-switch-partner"
      >
        <Dropdown
          selection
          placeholder="Partners"
          value={selectedPartner}
          options={partnersOptions}
          onChange={handleSwitchPartner}
        />
        <Button className={style.savePartnerButton} onClick={handleSavePartner}>
          Save
        </Button>
      </Modal>

      {modals}

      <ErrorModal
        message="Error updating active partner."
        open={showErrorModal}
        onCloseClick={() => setShowErrorModal(false)}
      />
    </div>
  )
}
