import gql from 'graphql-tag'

export const ListLabOrderProducts = gql`
  query ListLabOrderProducts(
    $filter: LabOrderFilter
    $sort: LabOrderSort = { created_at: asc }
    $limit: PositiveInt = 20
    $page: NonNegativeInt = 1
  ) {
    listLabOrderProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      page: $page
    ) {
      products {
        _id
        name
        partner_id
        project_id
        patient_id
        order_codes
        order_status
        order_type
        created_at
        updated_at
        patient_name
        partner_name
        package_name
        stripe_reference_id
        stripe_reference_status
      }
      page
      pages
      total
    }
  }
`
