import React from 'react'
import { generatePath, NavLink } from 'react-router-dom'
import get from 'lodash/get'
import classnames from 'classnames'
import { format } from 'date-fns'

import style from './ReportDetails.module.css'
import { LAB_REPORTS, CLIENTS } from '../../config/routes'
import FancyID from '../Utils/FancyID'
import capitalize from '../../utils/capitalize'
import DocumentsDetails from './DocumentsDetails'

const LabOrderProviders = ({ details }) => (
  <span>{details.map((detail) => detail.producerId).join(', ')}</span>
)

export default function ReportDetails({ labOrder, refetch }) {
  if (!labOrder) return null

  const clientId = get(labOrder, 'patient.id')
  const orderedDate = format(new Date(labOrder.createdAt), 'MMM d, yyyy')

  return (
    <section className={style.container} data-test="lab-report-profile">
      <h1 className={style.name}>{labOrder.name}</h1>
      <span className={style.detailsItem}>
        Report ID:&nbsp;
        <NavLink
          to={generatePath(LAB_REPORTS.view, { labReportId: labOrder.id })}
        >
          <FancyID id={labOrder.id} />
        </NavLink>
      </span>
      {labOrder.summary && (
        <div className={style.summary}>
          <div className={classnames(style.summaryValue, style.summaryNormal)}>
            {get(labOrder, 'summary.normal', 0)}
          </div>
          <div
            className={classnames(style.summaryValue, style.summaryAbnormal)}
          >
            {get(labOrder, 'summary.abnormal', 0)}
          </div>
        </div>
      )}
      <span className={style.detailsItem}>
        Patient:{' '}
        <NavLink to={generatePath(CLIENTS.view, { clientId })}>
          {get(labOrder, 'patient.data.name.display')}
        </NavLink>
      </span>
      <span className={style.detailsItem}>
        {(labOrder.providerDetails && labOrder.providerDetails.length && (
          <div>
            Lab(s): <LabOrderProviders details={labOrder.providerDetails} />{' '}
            <br />
          </div>
        )) ||
          null}
      </span>
      <span className={style.detailsItem}>Ordered: {orderedDate}</span>
      {labOrder.status && (
        <span className={style.detailsItem}>
          Status:{' '}
          <span
            className={classnames('ui', 'small', 'label', {
              violet: labOrder.status === 'ordered',
              blue: labOrder.status === 'started',
              grey: labOrder.status === 'partial',
              green: labOrder.status === 'completed',
              pink: labOrder.status === 'final',
            })}
          >
            {capitalize(labOrder.status).replace(/_/g, ' ')}
          </span>
        </span>
      )}
      {labOrder.resultViewed.date && (
        <span className={style.detailsItem}>
          Viewed:{' '}
          {format(new Date(labOrder.resultViewed.date), 'MMM dd, yyyy hh:mm a')}
        </span>
      )}
      {labOrder.resultReviewed.date && (
        <span className={style.detailsItem}>
          Reviewed:{' '}
          {format(
            new Date(labOrder.resultReviewed.date),
            'MMM dd, yyyy hh:mm a',
          )}
        </span>
      )}
      {labOrder.resultReviewed.date && (
        <span className={style.detailsItem}>
          Reviewed by: {labOrder.resultReviewed?.by?.name?.display}
        </span>
      )}

      <DocumentsDetails labOrder={labOrder} refetch={refetch} />
    </section>
  )
}
