/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Popup, Icon } from 'semantic-ui-react'

import style from './ReportDetails.module.css'
import PDFLink from '../PDFLink'
import LoadingDots from '../LoadingDots/LoadingDots'

const FETCH_ORDER_DOCUMENTS_INTERVAL = 1000 * 60

export const documentTypes = Object.freeze({
  QUEST_AOE: 'AoeUrl',
  REQ_FORM: 'ReqFormPdf',
  ABN_REQ_FORM: 'AbnFormPdf',
  RESULT_DOCUMENT: 'ResultDocument',
  SPECIMEN_LABEL: 'SpecimenLabelPdf',
})

export default function DocumentDetails({ labOrder, refetch }) {
  const { orderDocuments, resultDocuments, reqFormsLoading } = labOrder
  // If PDF is not available yet, fetch refetch every 20 seconds
  useEffect(() => {
    let fetchNewDataInterval
    if (fetchNewDataInterval) {
      clearInterval(fetchNewDataInterval)
    }

    if (orderDocuments) {
      if (reqFormsLoading) {
        fetchNewDataInterval = setInterval(() => {
          refetch()
        }, FETCH_ORDER_DOCUMENTS_INTERVAL)
      }
    }

    return () => {
      clearInterval(fetchNewDataInterval)
    }
  }, [orderDocuments, refetch, reqFormsLoading])

  const getDocumentLink = (document) => {
    const names = {
      [documentTypes.QUEST_AOE]: 'Quest AOE Form',
      [documentTypes.REQ_FORM]: 'Requisition Form',
      [documentTypes.ABN_REQ_FORM]: 'ABN Form',
      [documentTypes.SPECIMEN_LABEL]: 'Specimen Label',
    }

    if (document.url) {
      return (
        <p key={document.url} className={style.detailsItem}>
          <PDFLink url={document.url}>
            {names[document.typename]}
            {document.typename === documentTypes.SPECIMEN_LABEL && (
              <Popup
                trigger={<Icon name="info circle" style={{ marginLeft: 2 }} />}
                content="Specimen Label: This label can be used for lab specimens drawn in the provider's office."
                position="right center"
              />
            )}
          </PDFLink>
        </p>
      )
    }

    return null
  }

  /// Quest Result Report Base64 to PDF viewer
  const getResultsReportLink = (document) => {
    if (document.url && document.typename === documentTypes.RESULT_DOCUMENT) {
      return (
        <p key={document.url} className={style.detailsItem}>
          <PDFLink url={document.url}>Results Report</PDFLink>
        </p>
      )
    }
    return null
  }

  const showQuestResults = (data) => {
    var byteCharacters = atob(data)
    var byteNumbers = new Array(byteCharacters.length)
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    var byteArray = new Uint8Array(byteNumbers)
    var file = new Blob([byteArray], { type: 'application/pdf;base64' })
    var fileURL = URL.createObjectURL(file)
    window.open(fileURL)
  }

  return (
    <div className={style.documents}>
      {!!orderDocuments.length &&
        orderDocuments.map((document) => getDocumentLink(document))}
      {!!resultDocuments.length &&
        resultDocuments.map((document) =>
          getDocumentLink({
            typename: document.typename,
            url: document.resultsPdfReport.url,
          }),
        )}

      {labOrder.resultDocumentsBase64.pdfBase64 ? (
        <span
          className={style.reportLink}
          onClick={() =>
            showQuestResults(labOrder.resultDocumentsBase64.pdfBase64)
          }
        >
          Results Report
        </span>
      ) : (
        !!resultDocuments.length &&
        resultDocuments.map((document) =>
          getResultsReportLink({
            typename: document.typename,
            url: document.resultsPdfReport.url,
          }),
        )
      )}

      {reqFormsLoading && <LoadingDots text="Loading Order documents" />}
    </div>
  )
}
