/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { Modal, Button } from '@labsavvyapp/ui-components'

import generateLabOrder from '../../../utils/generate-lab-order-label'
import style from './PrintLabelModal.module.css'

export default function PrintLabelModal({
  open,
  onCloseClick,
  clientNumber,
  labRefNumber,
  patientName,
}) {
  const [selectedPrinter, setSelectedPrinter] = useState()
  const [printerOptions, setPrinterOptions] = useState([])
  const [label, setLabel] = useState(null)
  const [dymoInitialized, setDymoInitialized] = useState(false)
  const [labelImage, setLabelImage] = useState(null)

  useEffect(() => {
    if (open && checkDymoIsWorking()) {
      loadPrinters()

      setLabel(
        generateLabOrder(clientNumber, labRefNumber, patientName, window.dymo),
      )
    }
  }, [open])

  useEffect(() => {
    if (label && checkDymoIsWorking()) {
      let pngData = label.render()

      if (labelImage) {
        labelImage.src = 'data:image/png;base64,' + pngData
      }
    }
  }, [label, labelImage])

  const loadPrinters = () => {
    const printers = window.dymo.label.framework.getPrinters()

    if (printers.length) {
      setPrinterOptions(
        printers.map((printer) => ({
          key: printer.name,

          value: printer.name,

          text: printer.name,
        })),
      )

      setSelectedPrinter(printers[0].name)
    }
  }

  const printLabel = () => {
    try {
      label.print(selectedPrinter)
    } catch (e) {
      console.log(e.message || e)
    }
  }

  const checkDymoIsWorking = () => {
    try {
      if (!dymoInitialized && window._createFramework) {
        window._createFramework()

        setDymoInitialized(true)
      }

      return (
        window.dymo &&
        window.dymo.label.framework.checkEnvironment().errorDetails === ''
      )
    } catch {
      return false
    }
  }

  const onRefChange = useCallback((node) => {
    if (node !== null) {
      setLabelImage(node)
    }
  }, [])

  const disablePrintButton = label === null || printerOptions.length === 0

  return (
    <div>
      <Modal
        open={open}
        onCloseClick={onCloseClick}
        title="Print label"
        className={style.modal}
      >
        {printerOptions.length ? (
          <>
            <div>
              <p>Label preview:</p>

              <img ref={onRefChange} alt="Label preview" />
            </div>

            <div>
              <label htmlFor="printers">Select a printer:</label>

              <Dropdown
                id="printers"
                selection
                value={selectedPrinter}
                options={printerOptions}
                onChange={(_, { value }) => setSelectedPrinter(value)}
                data-test="printers-dropdown"
              />
            </div>
          </>
        ) : (
          <div>
            Please connect your printer and{' '}
            <a
              href="https://www.dymo.com/en-US/online-support/dymo-user-guides"
              target="_blank"
              rel="noreferrer"
            >
              install the required Software.
            </a>
          </div>
        )}

        <div className={style.buttons}>
          <Button
            onClick={printLabel}
            className={style.primary}
            disabled={disablePrintButton}
          >
            Print Label
          </Button>

          <Button onClick={onCloseClick} variant="secondary">
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  )
}
/* eslint-enable */
