import React from 'react'
import { Helmet } from 'react-helmet-async'

const HtmlHeaders = () => (
  <Helmet
    base={{ href: `${process.env.PUBLIC_URL}/` }}
    defaultTitle="LabSavvy Provider"
    titleTemplate="%s | LabSavvy Provider"
    htmlAttributes={{ lang: 'en' }}
  >
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
    <meta name="application-name" content="LabSavvy" />
    <meta name="theme-color" content="#594a9b" />
    <meta name="mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-status-bar-style" content="default" />
    <meta name="apple-mobile-web-app-title" content="LabSavvy" />
    <link rel="preconnect" href={process.env.REACT_APP_BACKEND_URL} />
    <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/favicon.ico`} />
    <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest.json`} />
  </Helmet>
)

export default HtmlHeaders
